import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/global.scss'
import { Toast  } from 'vant';
import 'vant/lib/index.css';

const pinia = createPinia();
createApp(App).use(router).use(ElementPlus).use(pinia).use(Toast).mount('#app')
