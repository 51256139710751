import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // name: 'home',
    redirect: '/home',
    // component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '',
        redirect: '/travelPlan'
      },
      {
        path: '/travelPlan',
        name: 'travelPlan',
        component: () => import('@/views/TravelPlan.vue')
      },
      {
        path: '/travelDetail',
        name: 'travelDetail',
        component: () => import('@/views/TravelDetail.vue')
      },
      {
        path: '/record',
        name: 'record',
        component: () => import('@/views/RecordView.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/AccountView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/PrivacyView.vue')
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('@/views/AboutView.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
